import React from "react"
import { graphql } from "gatsby"
import Container from "../../components/common/Container"
import GridTwo from "../../components/common/GridTwo"
import GridThree from "../../components/common/GridThree"
import CardPrint from "../../components/common/CardPrint"
import Layout from "../../components/layouts/Default"
import { H2 } from "../../components/typography/Headings"
import P from "../../components/typography/P"
import PageHeader from "../../components/common/PageHeader"
import Breadcrumb from "../../components/common/Breadcrumb"

export default function Page({ data, location }) {

  return (
    <Layout location={location} title="Bücher" desc="">
      <PageHeader title="Bücher" image={data.header} />
      <Breadcrumb pages={[{ name: "Verlag", to: "/verlag/" }, { name: "Bücher", to: "/verlag/buecher/" }]} />

      <Container className="space-y-8 md:space-y-16">
      <div>
          <div className="mb-8 md:mb-16">
            <H2 className="mb-5 font-display">Bücher</H2>
            <GridTwo>
              <div className="space-y-5">
                <P>
                  Der Herausgeber einer Londoner Zeitung startete eine Umfrage
                  zum Thema: „Bücher, die mir geholfen haben.“ Eine Antwort
                  lautete: „Das Kochbuch meiner Mutter und das Scheckbuch meines
                  Vaters.“
                </P>
                <P>
                  Ein Buch, das NATURBLICK herausbringt, hilft anders: Es ist
                  wie ein großzügiger Gastgeber in Natur und Wildnis. Es deckt
                  seinen Lesern den Tisch mit reichen Eindrücken. Es verführt
                  seine Leser, in der Wildnis zu hören, zu sehen und zu riechen,
                  als seien sie mittendrin. Und das auf jeder Seite!{" "}
                </P>
                <P>
                  Ein Gelehrter antwortete einst auf die Frage, warum man wohl
                  geliehene Bücher so selten zurückbekomme: „Weil es leichter
                  ist, die Bücher zu behalten, als das, was drin steht.“
                </P>
                <P>
                  Darauf können wir nur sagen: Dieser Gelehrte hat noch kein
                  Buch von NATURBLICK gelesen!{" "}
                </P>
              </div>
            </GridTwo>
          </div>
          <GridThree>
            {data.buecher.nodes.map((pub, i) => {
              return (
                <CardPrint
                  image={pub.remoteCoverImage}
                  title={pub.title}
                  link={`/verlag/buecher/${pub.title_slug}/`}
                  key={i}
                />
              )
            })}
          </GridThree>
        </div>

      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "pages/verlag/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(transformOptions: {cropFocus: ATTENTION}, width: 1600)
      }
    }
    buecher: allPublikationen(
        sort: { fields: date, order: DESC }
        filter: { art: { eq: "Bücher" } }
      ) {
        nodes {
          remoteCoverImage {
            childImageSharp {
              gatsbyImageData(transformOptions: {cropFocus: ATTENTION}, width: 1024)
            }
          }
          title
          title_slug
        }
      }
  }
`
